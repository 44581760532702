body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #16565c; /* Dark teal background */
  --s: 75px; /* control the size*/
  
  --_g: radial-gradient(#0000 70%,#235256 71%);
  background:
    var(--_g),var(--_g) calc(var(--s)/2) calc(var(--s)/2),
    conic-gradient(#3b6569 25%,#14566c 0 50%,#034d54 0 75%,#286a70 0);
  background-size: var(--s) var(--s); 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

